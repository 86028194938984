
import TopMenu from '@/topMenu.json'
import TopRightMenu from '@/topRightMenu.json'

export default {
  name: 'MainMenu',

  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: [...TopMenu],
      subLink: [],
      activeMenu: [],
      concatMenu: [...TopMenu, ...TopRightMenu],
      menuFooter: {
        active: true,
        phone: '0 (850) 225 10 10',
      }
    }
  },
  computed: {
    getMenu() {
      if (this.activeMenu.length === 0) {
        return this.concatMenu;
      }
      
      const lastActiveMenu = this.activeMenu[this.activeMenu.length - 1];
      
      // Handle different menu types
      if (lastActiveMenu.menuType === 'list' && lastActiveMenu.links) {
        return lastActiveMenu.links;
      } else if (lastActiveMenu.subMenu && lastActiveMenu.subMenu.length > 0) {
        return lastActiveMenu.subMenu;
      } else if (lastActiveMenu.links && lastActiveMenu.links.length > 0) {
        return lastActiveMenu.links;
      }
      
      return [];
    },
    itemHasSubMenu() {
      if (!this.activeMenu.length) return false;
      const lastMenu = this.activeMenu[this.activeMenu.length - 1];
      return !!(lastMenu.subMenu?.length > 0 || lastMenu.links?.length > 0);
    },
    menuType() {
      if (!this.activeMenu.length) return 'default';
      const lastMenu = this.activeMenu[this.activeMenu.length - 1];
      return lastMenu.menuType || 'default';
    },
  },
  watch: {
    status() {
      this.activeMenu = []
      this.subLink = []
    },
  },

  methods: {
    setActiveMenu(menu) {
      // Check if menu has submenu or links before pushing
      if (menu.subMenu?.length > 0 || menu.links?.length > 0) {
        this.activeMenu.push(menu);
        if (menu.subLink) {
          this.subLink = menu.subLink;
        } else {
          this.subLink = [];
        }
      }
    },
    goBack() {
      this.activeMenu.pop()
      this.$emit('close-menu')
    },
    initUrl(url, external = false) {
      if (external) return url
      return url ? `/${url.replace(/^\//, '')}` : '#'
    },
  },
}
